import PropTypes from "prop-types";
import dayjs from "dayjs";
import { isNil } from "ramda";

import { SHARE_ACTIONS, INVITE_STATUS } from "enums";
import { buildEventDate } from "utils/dateTimeUtils";
import Presenter from "utils/PropTypesPresenter";
import GuestPresenter from "presenters/GuestPresenter";
import EcardTemplateGroupPresenter from "presenters/EcardTemplateGroupPresenter";
import ProductSharedProperties from "presenters/shared/Product";

export default new Presenter(
  {
    ...ProductSharedProperties,
    shareThumbnail: PropTypes.string,
    templateGroupId: PropTypes.string,
    templateId: PropTypes.string,
    userId: PropTypes.number,
    status: PropTypes.string,
    themeId: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    host: PropTypes.string,
    location: PropTypes.string,
    details: PropTypes.string,
    eventDate: PropTypes.string,
    allDay: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    castings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        roleRank: PropTypes.number,
        headAsset: PropTypes.string,
        jawSvg: PropTypes.string,
        headId: PropTypes.string,
      }),
    ),
    settings: PropTypes.shape({
      from: PropTypes.string,
      subject: PropTypes.string,
      canSeeGuestList: PropTypes.bool,
      invitesPerGuest: PropTypes.number,
      public: PropTypes.bool,
    }),
    guests: PropTypes.arrayOf(GuestPresenter.shape()),
  },
  {
    template(i, tg) {
      return EcardTemplateGroupPresenter.templateById(tg, i?.templateId);
    },
    templateGroupType(i, tg) {
      return EcardTemplateGroupPresenter.templateGroupType(tg);
    },
    isLive(invite) {
      return this.status(invite) === INVITE_STATUS.LIVE;
    },
    isDraft(invite) {
      return this.status(invite) === INVITE_STATUS.DRAFT;
    },
    isDeleted(invite) {
      return this.status(invite) === INVITE_STATUS.DELETED;
    },
    isValid(invite) {
      return !!(this.title(invite) && this.eventDate(invite));
    },
    isExpired(invite) {
      return dayjs().isAfter(this.eventDate(invite));
    },
    isPending(invite) {
      return this.status(invite) === INVITE_STATUS.PENDING;
    },
    isUpcomingEvent(invite) {
      return (
        (this.status(invite) === INVITE_STATUS.LIVE || this.status(invite) === INVITE_STATUS.PENDING) &&
        !this.isExpired(invite)
      );
    },
    from(invite) {
      return this.settings(invite)?.from;
    },
    subject(invite) {
      return this.settings(invite)?.subject;
    },
    public(invite) {
      return this.settings(invite)?.public;
    },
    canSeeGuestList(invite) {
      return this.settings(invite)?.canSeeGuestList;
    },
    invitesPerGuest(invite) {
      return this.settings(invite)?.invitesPerGuest;
    },
    displayDay(invite) {
      return invite?.eventDate ? buildEventDate(invite.eventDate).date : "";
    },
    displayTime(invite) {
      return invite?.eventDate ? buildEventDate(invite.eventDate).timeWithZone : "";
    },
    attendingGuests(invite) {
      return this.guests(invite)?.filter((g) => GuestPresenter.isAttending(g));
    },
    notAttendingGuests(invite) {
      return this.guests(invite)?.filter((g) => GuestPresenter.isNotAttending(g));
    },
    notRespondedGuests(invite) {
      return this.guests(invite)?.filter((g) => !GuestPresenter.hasResponded(g));
    },
    maybeAttendingGuests(invite) {
      return this.guests(invite)?.filter((g) => GuestPresenter.isMaybeAttending(g));
    },
    guestsWithEmail(invite) {
      return this.guests(invite)?.filter((g) => GuestPresenter.hasEmail(g));
    },
    hasGuestsWithEmail(invite) {
      return this.guestsWithEmail(invite)?.length > 0;
    },
    guestBySlug(invite, guestSlug) {
      return this.guests(invite)?.find((g) => GuestPresenter.id(g) === guestSlug);
    },
    trackShareAction(invite) {
      return this.isPending(invite) ? SHARE_ACTIONS.UPDATE : SHARE_ACTIONS.ORIGINAL;
    },
    isEventUpdated(invite) {
      return !isNil(this.title(invite)) && this.createdAt(invite) !== this.updatedAt(invite);
    },
    changedValues(currentValues, invite) {
      const diffProps = [];

      const keys = {
        from: "from",
        subject: "subject",
        canSeeGuestList: "show_guest_list",
        invitesPerGuest: "RSVPs_per_guest",
      };

      Object.keys(keys).forEach((key) => {
        if (currentValues[key] !== this[key](invite)) diffProps.push(keys[key]);
      });

      return diffProps;
    },
    populatedFieldNames(invite) {
      if (isNil(invite)) return "";

      const value = [];
      const fields = {
        title: "event_title",
        host: "event_host",
        description: "event_description",
        eventDate: "event_date",
        location: "event_location",
        details: "event_details",
      };

      Object.keys(fields).forEach((field) => {
        if (this[field](invite)) value.push(fields[field]);
      });

      return value.join(", ");
    },
  },
);
