import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";
import MorphablePackGroupPresenter from "presenters/MorphablePackGroupPresenter";
import MorphablePackPresenter from "presenters/MorphablePackPresenter";
import MorphedPhotoPresenter from "presenters/MorphedPhotoPresenter";
import ProductSharedProperties from "presenters/shared/Product";

export default new Presenter(
  {
    ...ProductSharedProperties,
    photoPackSlug: PropTypes.string,
    status: PropTypes.string,
    morphedPhotos: PropTypes.arrayOf(MorphedPhotoPresenter.shape()),
    previewMorphedPhoto: MorphedPhotoPresenter.shape(),
    morphablePackGroup: MorphablePackGroupPresenter.shape(),
    morphablePack: MorphablePackPresenter.shape(),
  },
  {
    photos(morphedPack) {
      return [...morphedPack?.morphedPhotos, morphedPack?.previewMorphedPhoto];
    },
    morphablePackGroupName(morphedPack) {
      return MorphablePackGroupPresenter.name(morphedPack?.morphablePackGroup);
    },
    template(morphedPack) {
      return this.morphablePack(morphedPack);
    },
    templateId(morphedPack) {
      return this.photoPackSlug(morphedPack);
    },
    templateGroupId(morphedPack) {
      return this.morphablePackGroup(morphedPack)?.id;
    },
    templateGroupType(morphedPack, tg) {
      return MorphablePackGroupPresenter.templateGroupType(tg);
    },
  },
);
