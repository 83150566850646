import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";
import TemplateSharedProperties from "presenters/shared/Template";

export default new Presenter(
  {
    ...TemplateSharedProperties,
    coverPhoto: PropTypes.string,
    skintone: PropTypes.string,
    gender: PropTypes.string,
    templateGroupSlug: PropTypes.string,
    templateGroupName: PropTypes.string,
    templateGroupType: PropTypes.string,
  },
  {
    analyticsItem(morphablePack) {
      return {
        item_id: this.templateGroupSlug(morphablePack),
        item_name: this.templateGroupName(morphablePack),
        item_variant: this.skintone(morphablePack),
      };
    },
  },
);
