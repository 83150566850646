import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, Link, Button, Grid, ClickAwayListener } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandLess } from "@mui/icons-material";

import MegaMenuItemPresenter from "presenters/MegaMenuItemPresenter";

import SubNavigation from "../SubNavigation";

import useStyles from "./useStyles";

const MenuItem = ({ menuItem }) => {
  const classes = useStyles();
  const [collapseSubMenu, setCollapseSubMenu] = useState(false);
  const name = MegaMenuItemPresenter.name(menuItem);
  const subMenu = MegaMenuItemPresenter.childrenMenu(menuItem);

  const handleClickSubMenu = (value) => setCollapseSubMenu(value);

  const renderSimpleLink = () => {
    return MegaMenuItemPresenter.isExternalLinkTo(menuItem) ? (
      <Link
        id={MegaMenuItemPresenter.createComponentId(menuItem)}
        className={classes.link}
        href={MegaMenuItemPresenter.linkTo(menuItem)}
      >
        {name}
      </Link>
    ) : (
      <Link
        id={MegaMenuItemPresenter.createComponentId(menuItem)}
        exact
        activeClassName={classes.activelink}
        className={classes.link}
        to={MegaMenuItemPresenter.linkTo(menuItem)}
        component={NavLink}
      >
        {name}
      </Link>
    );
  };

  const renderCollapsableLink = () => {
    return (
      <ClickAwayListener onClickAway={() => handleClickSubMenu(false)}>
        <Grid className={classes.link} onClick={() => handleClickSubMenu(!collapseSubMenu)}>
          <Button
            id={`button-${MegaMenuItemPresenter.createComponentId(menuItem)}`}
            variant="link"
            endIcon={collapseSubMenu ? <ExpandMoreIcon /> : <ExpandLess />}
          >
            {name}
          </Button>
          <Collapse in={collapseSubMenu} className={classes.subMenu}>
            <SubNavigation menuItem={menuItem} />
          </Collapse>
        </Grid>
      </ClickAwayListener>
    );
  };

  return subMenu && subMenu.length === 0 ? renderSimpleLink() : renderCollapsableLink();
};

MenuItem.propTypes = {
  menuItem: MegaMenuItemPresenter.shape(),
};

export default MenuItem;
