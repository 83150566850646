import { useSelector } from "react-redux";

import { useOfferGroupsActions } from "store/OfferGroupsSlice";

const useOfferGroups = () => {
  const { loadOfferGroups, switchCurrentOffer, clearCurrentOffer } = useOfferGroupsActions();

  const { currentOffer, offerGroups } = useSelector((state) => state.OfferGroupsSlice);

  return {
    currentOffer,
    offerGroups,
    offers: offerGroups?.offers || [],
    loadOfferGroups,
    switchCurrentOffer,
    clearCurrentOffer,
  };
};

export default useOfferGroups;
