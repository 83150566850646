import { SHARE_METHODS } from "./analytics";

const SHARE_TYPES = Object.freeze({
  FACEBOOK: "facebook",
  SMS: "sms",
  EMAIL: "email",
  MORE: "more",
});

const SHARE_TYPES_ANALYTIC_EVENTS = Object.freeze({
  [SHARE_TYPES.FACEBOOK]: SHARE_METHODS.FACEBOOK,
  [SHARE_TYPES.SMS]: SHARE_METHODS.SMS,
  [SHARE_TYPES.EMAIL]: SHARE_METHODS.EMAIL,
  [SHARE_TYPES.MORE]: SHARE_METHODS.BROWSER,
});

export { SHARE_TYPES, SHARE_TYPES_ANALYTIC_EVENTS };
