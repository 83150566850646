import PropTypes from "prop-types";

import Presenter from "utils/PropTypesPresenter";
import TemplateSharedProperties from "presenters/shared/Template";

export default new Presenter(
  {
    ...TemplateSharedProperties,
    castNum: PropTypes.number,
    castingCardThumb: PropTypes.string,
    mobileMp4Video: PropTypes.string,
    mp4Video: PropTypes.string,
    swfVideo: PropTypes.string,
    templateGroupName: PropTypes.string,
    templateGroupSlug: PropTypes.string,
    templateGroupType: PropTypes.string,
    webglFeatureVideo: PropTypes.string,
    webglPositionData: PropTypes.string,
    manifest: PropTypes.string,
    positionData: PropTypes.string,
  },
  {
    isLive(t) {
      return this.status(t)?.toLowerCase() === "live";
    },
    isHidden(t) {
      return this.status(t)?.toLowerCase() === "hidden";
    },
    isDeleted(t) {
      return this.status(t)?.toLowerCase() === "deleted";
    },
    analyticsItem(t) {
      return {
        item_id: this.templateGroupSlug(t),
        item_name: this.templateGroupName(t),
        item_variant: this.castNum(t),
      };
    },
  },
);
