import PropTypes from "prop-types";

import { ECARD_TYPES } from "enums";
import InvitePresenter from "presenters/InvitePresenter";
import MakePresenter from "presenters/MakePresenter";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";

class EcardPresenterBase {
  static getPresenter(ecard) {
    switch (ecard.type) {
      case ECARD_TYPES.MAKE:
        return MakePresenter;
      case ECARD_TYPES.INVITE:
        return InvitePresenter;
      case ECARD_TYPES.MORPHABLE:
        return MorphedPackPresenter;
      default:
        throw new Error(`Unknown content type ${ecard.type}`);
    }
  }

  static isMake(ecard) {
    return ecard.type === ECARD_TYPES.MAKE;
  }

  static isInvite(ecard) {
    return ecard.type === ECARD_TYPES.INVITE;
  }

  static isMorphable(ecard) {
    return ecard.type === ECARD_TYPES.MORPHABLE;
  }

  static shape() {
    return PropTypes.oneOfType([InvitePresenter.shape(), MakePresenter.shape(), MorphedPackPresenter.shape()]);
  }
}

const EcardPresenter = new Proxy(EcardPresenterBase, {
  get(target, key) {
    if (!["name", "length", "prototype"].includes(key) && key in target) return target[key];

    return (ecard, ...args) => {
      if (!ecard?.type) return undefined;

      const presenter = target.getPresenter(ecard);
      const method = presenter[key];

      if (typeof method === "function") {
        return method.call(presenter, ecard, ...args);
      }

      throw new Error(`Method ${key} does not exist for type ${ecard.type}`);
    };
  },
});

export default EcardPresenter;
