import PropTypes from "prop-types";

import { TEMPLATE_GROUP_TYPES } from "enums";
import EcardTemplateGroupPresenter from "presenters/EcardTemplateGroupPresenter";
import MorphablePackGroupPresenter from "presenters/MorphablePackGroupPresenter";

class TemplateGroupPresenterBase {
  static getPresenter(templateGroup) {
    switch (templateGroup.templateGroupType) {
      case TEMPLATE_GROUP_TYPES.starringYouAI:
        return MorphablePackGroupPresenter;
      case TEMPLATE_GROUP_TYPES.straightUpEcard:
      case TEMPLATE_GROUP_TYPES.starringYouEcard:
      case TEMPLATE_GROUP_TYPES.starringYouClip:
      case TEMPLATE_GROUP_TYPES.starringYouVertical:
      case TEMPLATE_GROUP_TYPES.starringYouInvite:
      case TEMPLATE_GROUP_TYPES.starringYouInviteGif:
        return EcardTemplateGroupPresenter;
      default:
        throw new Error(`Unknown template group type ${templateGroup.templateGroupType}`);
    }
  }

  static shape() {
    return PropTypes.oneOfType([EcardTemplateGroupPresenter.shape(), MorphablePackGroupPresenter.shape()]);
  }

  static isStraightUp(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.straightUpEcard;
  }

  static isStarringYouClip(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouClip;
  }

  static isStarringYouVertical(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouVertical;
  }

  static isStarringYou(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouEcard;
  }

  static isStarringYouInvite(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouInvite;
  }

  static isStarringYouInviteGif(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouInviteGif;
  }

  static isInvite(templateGroup) {
    return this.isStarringYouInvite(templateGroup) || this.isStarringYouInviteGif(templateGroup);
  }

  static isStarringYouAI(templateGroup) {
    return templateGroup?.templateGroupType === TEMPLATE_GROUP_TYPES.starringYouAI;
  }
}

const TemplateGroupPresenter = new Proxy(TemplateGroupPresenterBase, {
  get(target, key) {
    if (!["name", "length", "prototype"].includes(key) && key in target) return target[key];

    return (templateGroup, ...args) => {
      if (!templateGroup?.templateGroupType) return undefined;

      const presenter = target.getPresenter(templateGroup);
      const method = presenter[key];

      if (typeof method === "function") {
        return method.call(presenter, templateGroup, ...args);
      }

      throw new Error(`Method ${key} does not exist for template group type ${templateGroup.templateGroupType}`);
    };
  },
});

export default TemplateGroupPresenter;
