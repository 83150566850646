import PropTypes from "prop-types";

import { TEMPLATE_GROUP_TYPES } from "enums";
import EcardTemplatePresenter from "presenters/EcardTemplatePresenter";
import MorphablePackPresenter from "presenters/MorphablePackPresenter";

class TemplatePresenterBase {
  static getPresenter(template) {
    switch (template.templateGroupType) {
      case TEMPLATE_GROUP_TYPES.starringYouAI:
        return MorphablePackPresenter;
      case TEMPLATE_GROUP_TYPES.straightUpEcard:
      case TEMPLATE_GROUP_TYPES.starringYouEcard:
      case TEMPLATE_GROUP_TYPES.starringYouClip:
      case TEMPLATE_GROUP_TYPES.starringYouVertical:
      case TEMPLATE_GROUP_TYPES.starringYouInvite:
      case TEMPLATE_GROUP_TYPES.starringYouInviteGif:
        return EcardTemplatePresenter;
      default:
        throw new Error(`Unknown template group type ${template.templateGroupType}`);
    }
  }

  static shape() {
    return PropTypes.oneOfType([EcardTemplatePresenter.shape(), MorphablePackPresenter.shape()]);
  }
}

const TemplatePresenter = new Proxy(TemplatePresenterBase, {
  get(target, key) {
    if (!["name", "length", "prototype"].includes(key) && key in target) return target[key];

    return (template, ...args) => {
      if (!template?.templateGroupType) return undefined;

      const presenter = target.getPresenter(template);
      const method = presenter[key];

      if (typeof method === "function") {
        return method.call(presenter, template, ...args);
      }

      throw new Error(`Method ${key} does not exist for template group type ${template.templateGroupType}`);
    };
  },
});

export default TemplatePresenter;
