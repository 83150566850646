/* global DetectUserAgent */
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "ramda";

import UserPresenter from "presenters/UserPresenter";
import { getTimeZone, getCurrentDateInUTC } from "utils/dateTimeUtils";
import { jjLogger } from "utils/logUtils";
import { JIBJAB_TRACKING_ID } from "utils/localStorage";
import { METRICS } from "enums/tracking";

export const METRIC = METRICS.PREANON;
const URL = process.env.JIBJAB_ANALYTICS_URL;

export const getTrackingId = () => localStorage.getItem(JIBJAB_TRACKING_ID);

export const setupTrackingId = () => {
  if (DetectUserAgent.isPrerender()) return;

  const trackingId = getTrackingId();

  if (trackingId) {
    jjLogger.log("tracking.js: trackingId already set");
    return;
  }

  jjLogger.log("tracking.js: setting trackingId");
  localStorage.setItem(JIBJAB_TRACKING_ID, uuidv4());
};

export const eventData = (event, user, extraData = {}) => {
  const trackingId = getTrackingId();

  if (!trackingId) {
    jjLogger.logError(`trackEvent: - Tracking ID is not set for ${event} event`);
    return {};
  }

  return {
    event,
    tracking_id: trackingId,
    event_timestamp: getCurrentDateInUTC(),
    metric: METRIC,
    referrer_url: document.referrer,
    current_url: `${window.location.origin}${window.location.pathname}`,
    user_agent: navigator.userAgent,
    env: process.env.DEPLOY_ENV,
    user_id: UserPresenter.id(user),
    user_status: UserPresenter.analyticsStatus(user),
    client_timezone: getTimeZone(),
    query_string: window.location.search,
    ...extraData,
  };
};

const sendBeacon = (payload) => {
  if (!URL || DetectUserAgent.isPrerender() || isEmpty(payload)) return;

  navigator.sendBeacon(URL, JSON.stringify(payload));
};

export const trackEvent = (eventName, user, extraData = {}) => {
  const values = eventData(eventName, user, extraData);

  sendBeacon(values);
};

export const pageViewEvent = (user) => {
  trackEvent("page_view", user);
};

export const signUpEvent = (user) => {
  trackEvent("sign_up", user);
};

export const purchaseEvent = (user) => {
  const subscriptionId = UserPresenter.activeSubscription(user).id;
  trackEvent("purchase", user, { subscription_id: subscriptionId });
};

export const loginEvent = (user) => {
  trackEvent("login", user);
};

export const draftContentEvent = (user) => {
  if (!UserPresenter.isAnonymous(user)) return;

  trackEvent("draft_content", user);
};

export const experimentViewedEvent = (experimentId, variationId) => {
  if (!experimentId || !variationId) {
    jjLogger.logDebug(`experimentViewedEvent: - ${!experimentId ? "Experiment" : "Variation"} ID is not set`);
  }

  const payload = {
    tracking_id: getTrackingId(),
    experiment_id: experimentId ?? null,
    variation_id: variationId ?? null,
    env: process.env.DEPLOY_ENV,
    metric: "viewed_experiment",
  };

  sendBeacon(payload);
};
