import { apiRoutesV2 } from "routes";
import FetchHelpers from "utils/FetchHelpers";

const getForceErrorParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const forceErrorParam = urlParams.get("force_error");

  return forceErrorParam ? { force_error: forceErrorParam } : {};
};

export default {
  index(params) {
    const url = apiRoutesV2.morphedPacks();
    return FetchHelpers.get(url, params);
  },
  create(params) {
    const url = apiRoutesV2.morphedPacks();
    return FetchHelpers.post(url, params);
  },
  show(slug) {
    const url = apiRoutesV2.morphedPacks(slug, "1.1");
    return FetchHelpers.get(url);
  },
  status(slug) {
    const url = `${apiRoutesV2.morphedPacks(slug)}/status`;
    return FetchHelpers.get(url);
  },
  previewPhoto(slug) {
    const url = `${apiRoutesV2.morphedPacks(slug)}/morphed_preview_photo`;

    return FetchHelpers.post(url, {}, undefined, getForceErrorParams());
  },
  standardPhotos(slug) {
    const url = `${apiRoutesV2.morphedPacks(slug)}/morphed_photos`;

    return FetchHelpers.post(url, {}, undefined, getForceErrorParams());
  },
};
