import PropTypes from "prop-types";

export default {
  id: PropTypes.string,
  name: PropTypes.string,
  premium: PropTypes.bool,
  status: PropTypes.string,
  tags: PropTypes.string,
  description: PropTypes.string,
  platform: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};
