const host = process.env.JIBJAB_API_HOST ? `https://${process.env.JIBJAB_API_HOST}` : "";

const DEFAULT_API_VERSION = "1";

const generateRoute = (path, version = DEFAULT_API_VERSION) => `${host}/v${version}/${path}`;

export default {
  rootPath: () => `${host}/v1`,
  invites: (slug) => (slug ? `${host}/v1/invites/${slug}` : `${host}/v1/invites`),
  morphedPacks: (slug, apiVersion) => generateRoute(`morphed_packs/${slug || ""}`, apiVersion),
  sourcePhotos: () => `${host}/v1/source_photos`,
  inviteEvents: () => `${host}/v1/invite_events`,
  inviteGuests: (inviteSlug, guestSlug) =>
    guestSlug ? `${host}/v1/invites/${inviteSlug}/guests/${guestSlug}` : `${host}/v1/invites/${inviteSlug}/guests`,
};
